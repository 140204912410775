<template>
  <div>
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div v-show="isLoaded">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="10"
        >
          <div
            v-if="hasPermissionToUpdate"
          >
            <b-row
              v-if="isViewMode"
            >
              <b-col
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              >
                <b-dropdown
                  id="actions"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  right
                  class="dropdown-icon-wrapper"
                >
                  <template #button-content>
                    <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="align-middle"
                    />
                  </template>

                  <b-dropdown-item
                    id="btn_editar"
                    :disabled="!hasPermissionToUpdate"
                    @click="enableEdition()"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Editar</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </div>
          <validation-observer
            ref="refFormObserver"
          >
            <b-form
              @submit="onSubmit"
            >
              <b-card
                class="mt-1"
                no-body
              >
                <b-card-title
                  class="mt-2 ml-2 mb-0"
                >
                  <svg-icon
                    type="mdi"
                    :path="mdiShieldKeyOutline"
                  />
                  <span class="d-inline ml-50">Permissão</span>
                </b-card-title>
                <hr>
                <b-card-body
                  class="pt-1"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="name"
                        rules="required"
                      >
                        <b-form-group
                          label="Nome"
                          label-for="name"
                        >
                          <b-form-input
                            id="name"
                            v-model="permissionsData.name"
                            :state="isViewMode ? null : getValidationState(validationContext)"
                            placeholder="Nome"
                            :readonly="isViewMode"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="8"
                    >
                      <!-- Description -->
                      <validation-provider
                        #default="validationContext"
                        name="description"
                      >
                        <b-form-group
                          label="Descrição"
                          label-for="description"
                        >
                          <b-form-input
                            id="permissionDescription"
                            v-model="permissionsData.description"
                            :state="isViewMode ? null : getValidationState(validationContext)"
                            :placeholder="isViewMode ? '' : 'Descrição'"
                            :readonly="isViewMode"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-card-body>

                <b-table
                  class="mb-2"
                  responsive
                  striped
                  :fields="tableColumnsOptionsPermission"
                  :items="permissionsData.options"
                  :busy="!isLoaded"
                >
                  <template #cell(app_option)="data">
                    {{ data.value }}
                  </template>
                  <template #cell(permission_read)="data">
                    <b-form-checkbox
                      v-model="permissionsData.options[data.index]['permission_read']"
                      name="checkbox-read"
                      :disabled="isViewMode"
                    />
                  </template>
                  <template #cell(permission_write)="data">
                    <b-form-checkbox
                      v-model="permissionsData.options[data.index]['permission_write']"
                      name="checkbox-write"
                      :disabled="isViewMode"
                      @change="setPermissionRead(data.index)"
                    />
                  </template>
                  <template #cell(permission_update)="data">
                    <b-form-checkbox
                      v-model="permissionsData.options[data.index]['permission_update']"
                      name="checkbox-update"
                      :disabled="isViewMode"
                      @change="setPermissionRead(data.index)"
                    />
                  </template>
                  <template #cell(permission_delete)="data">
                    <b-form-checkbox
                      v-model="permissionsData.options[data.index]['permission_delete']"
                      name="checkbox-delete"
                      :disabled="isViewMode"
                      @change="setPermissionRead(data.index)"
                    />
                  </template>
                </b-table>
              </b-card>

              <b-card
                class="mt-2"
                no-body
              >
                <b-card-title
                  class="mt-2 ml-2 mb-0"
                >
                  <svg-icon
                    type="mdi"
                    :path="mdiNoteSearchOutline"
                  />
                  <span class="d-inline ml-50">
                    Auditoria
                  </span>
                </b-card-title>

                <hr>

                <b-card-body
                  class="pt-1"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      class="d-flex pb-2"
                    >
                      <b-form-checkbox
                        id="audit_commitment"
                        v-model="permissionsData.audit_commitment"
                        name="checkbox-read"
                        :disabled="isViewMode"
                      >
                        Empenho
                      </b-form-checkbox>

                      <b-form-checkbox
                        id="audit_delivery"
                        v-model="permissionsData.audit_delivery"
                        name="checkbox-read"
                        :disabled="isViewMode"
                        class="ml-2"
                      >
                        Entrega
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>

              <b-row
                class="mt-2"
              >
                <b-col
                  class="d-flex justify-content-end buttonsSaveCancel"
                >
                  <b-button
                    v-if="!isViewMode"
                    id="permission_save"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    type="submit"
                  >
                    Salvar
                  </b-button>
                  <b-button
                    v-if="!isViewMode"
                    id="permission_cancel"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="outline-primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    @click="disableEdition(true)"
                  >
                    Cancelar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BTable,
  BCardTitle,
  BCardBody,
  BFormCheckbox,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import SvgIcon from '@jamescoyle/vue-icon'

import {
  mdiShieldKeyOutline,
  mdiNoteSearchOutline,
} from '@mdi/js'

import syslic from '@/syslic'
import router from '@/router'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BCardTitle,
    BCardBody,
    BFormCheckbox,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    SpinLoader,
    SvgIcon,
  },
  directives: {
    Ripple,
  },
  data() {
    let isViewMode = true
    let isCreateMode = false

    if (router.currentRoute.path.match(/.*\/edit\/.*/)
        || router.currentRoute.path.match(/.*\/create/)) {
      isViewMode = false
    }

    if (router.currentRoute.path.match(/.*\/create/)) {
      isCreateMode = true
    }

    const isToUpdatePermission = router.currentRoute.params.id !== undefined

    const permissionsData = {
      name: '',
      description: '',
      audit_commitment: false,
      audit_delivery: false,
      options: [
        {
          app_option: 'Usuários',
          permission_read: false,
          permission_write: false,
          permission_update: false,
          permission_delete: false,
        },
        {
          app_option: 'Permissões',
          permission_read: false,
          permission_write: false,
          permission_update: false,
          permission_delete: false,
        },
        {
          app_option: 'Empresas',
          permission_read: false,
          permission_write: false,
          permission_update: false,
          permission_delete: false,
        },
        {
          app_option: 'Clientes',
          permission_read: false,
          permission_write: false,
          permission_update: false,
          permission_delete: false,
        },
        {
          app_option: 'Licitações',
          permission_read: false,
          permission_write: false,
          permission_update: false,
          permission_delete: false,
        },
        {
          app_option: 'Configurações de Licitações',
          permission_read: false,
          permission_write: false,
          permission_update: false,
          permission_delete: false,
        },
        {
          app_option: 'Fornecedores',
          permission_read: false,
          permission_write: false,
          permission_update: false,
          permission_delete: false,
        },
        {
          app_option: 'Configurações de Fornecedores',
          permission_read: false,
          permission_write: false,
          permission_update: false,
          permission_delete: false,
        },
        {
          app_option: 'Produtos',
          permission_read: false,
          permission_write: false,
          permission_update: false,
          permission_delete: false,
        },
        {
          app_option: 'Configurações de Produtos',
          permission_read: false,
          permission_write: false,
          permission_update: false,
          permission_delete: false,
        },
        {
          app_option: 'Transporte',
          permission_read: false,
          permission_write: false,
          permission_update: false,
          permission_delete: false,
        },
        {
          app_option: 'Configurações de Transporte',
          permission_read: false,
          permission_write: false,
          permission_update: false,
          permission_delete: false,
        },
        {
          app_option: 'Pedidos',
          permission_read: false,
          permission_write: false,
          permission_update: false,
          permission_delete: false,
        },
        {
          app_option: 'Configurações de Pedidos',
          permission_read: false,
          permission_write: false,
          permission_update: false,
          permission_delete: false,
        },
        {
          app_option: 'Contratos',
          permission_read: false,
          permission_write: false,
          permission_update: false,
          permission_delete: false,
        },
        {
          app_option: 'Configurações de Contratos',
          permission_read: false,
          permission_write: false,
          permission_update: false,
          permission_delete: false,
        },
        {
          app_option: 'Comissões',
          permission_read: false,
          permission_write: false,
          permission_update: false,
          permission_delete: false,
        },
        {
          app_option: 'Relatórios',
          permission_read: false,
          permission_write: false,
          permission_update: false,
          permission_delete: false,
        },
      ],
    }

    const permissionsDataOrig = JSON.parse(JSON.stringify(permissionsData))

    const isLoaded = false
    return {
      required,
      permissionsData,
      permissionsDataOrig,
      isViewMode,
      isCreateMode,
      permissionId: null,
      isToUpdatePermission,
      isLoaded,
      mdiShieldKeyOutline,
      mdiNoteSearchOutline,
    }
  },
  computed: {
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.permission.can_edit
    },
  },
  created() {
    this.permissionId = router.currentRoute.params.id

    if (this.permissionId !== undefined) {
      syslic
        .permission
        .fetchProfilePermission(this.permissionId)
        .then(response => {
          Object.keys(this.permissionsDataOrig).forEach(key => {
            if ((key === 'options') && key in response.data) {
              response.data.options.forEach((option, indexOption) => {
                this.permissionsDataOrig.options.forEach((value, index) => {
                  if (value.app_option === response.data.options[indexOption].app_option) {
                    this.permissionsData.options[index] = response.data.options[indexOption]
                  }
                })
              })
            } else if (key in response.data) {
              this.permissionsData[key] = response.data[key]
            }
          })

          this.isLoaded = true
        })
        .catch(error => {
          if (error.status === 404) {
            router.push({ name: 'error-404' })
          }
          this.isLoaded = true
        })
    } else {
      this.isLoaded = true
    }
  },
  methods: {
    setPermissionRead(index) {
      this.permissionsData.options[index].permission_read = true
    },
    anyIsTrue(permission) {
      let isTrue = false
      Object.keys(permission.options).forEach(row => {
        if (permission.options[row].permission_read === true) {
          isTrue = true
        }
      })
      return isTrue
    },
    createPermission() {
      syslic
        .permission
        .addProfilePermission(this.permissionsData)
        .then(response => {
          this.permissionId = response.data.id
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Perfil de permissões adicionado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.disableEdition(false)
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar um novo perfil de permissões.',
              text: 'Não foi possível adicionar o perfil, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    updatePermission() {
      syslic
        .permission
        .updateProfilePermission(router.currentRoute.params.id, this.permissionsData)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Permissões atualizadas com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.disableEdition(false)
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualizar as permissões.',
              text:
                'Não foi possível atualizar as permissões, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    onSubmit(event) {
      event.preventDefault()
      const check = this.anyIsTrue(this.permissionsData)
      if (check === true) {
        if (this.isToUpdatePermission !== true) {
          this.createPermission()
        } else {
          this.updatePermission()
        }
      } else {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Falha ao criar o perfil de permissões.',
            text: 'Por favor selecione pelo menos uma permissão de visualizar.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            timeout: false,
          },
        })
      }
    },
    enableEdition() {
      this.isViewMode = false
      router.push({ name: 'permission-edit' })
    },
    disableEdition(isCancel) {
      if (this.isCreateMode) {
        if (isCancel) {
          router.push({ name: 'permission' })
        } else {
          router.push({
            name: 'permission-view',
            params: {
              id: this.permissionId,
            },
          })
        }
      } else {
        router.push({
          name: 'permission-view',
          params: {
            id: this.permissionId,
          },
        })
      }
    },
  },
  setup() {
    const toast = useToast()

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    const tableColumnsOptionsPermission = [
      { key: 'app_option', label: 'Módulo' },
      { key: 'permission_read', label: 'Visualizar' },
      { key: 'permission_write', label: 'Criar' },
      { key: 'permission_update', label: 'Editar' },
      { key: 'permission_delete', label: 'Excluir' },
    ]

    return {
      toast,
      getValidationState,
      tableColumnsOptionsPermission,
      refFormObserver,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

.card-title {
  color: $primary
}

.buttonsSaveCancel{
  .btn{
    min-width: 107px;
  }
}
</style>
